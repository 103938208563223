<template>
  <div>
    <div style="margin: 20px 0 0 20px" v-if="!isInfo">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div :class="isInfo ? 'authentication noPadding' : 'authentication '">
      <div class="authentication-cont flex-layout">
        <div>
          <div class="authentication-cont-item" v-if="!isInfo">
            <p><span>*</span><span>选择用户</span></p>
            <div>
              <el-select
                  v-model="userId"
                  filterable
                  remote
                  :remote-method="(query) => getUserList(query)"
                  style="width:300px;"
                  class="company-h"
                  :disabled="!!userName"
              >
                <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="item.nickname"
                    :value="item.id">
                </el-option>
              </el-select>
              <Button v-if="userId" type="primary" style="margin-left: 20px" @click="editUser">编辑</Button>
            </div>

          </div>
          <!--          <div class="authentication-cont-item">-->
          <!--            <p><span>*</span><span>支付宝账号</span></p>-->
          <!--            <Input v-model="alipayAccount" placeholder="请输入支付宝账号" style="width: 300px" />-->
          <!--          </div>-->
          <div class="authentication-cont-item">
            <p><span></span><span>邮箱</span></p>
            <Input v-model="email" :disabled="true" placeholder="请输入邮箱" style="width: 300px" />
          </div>
        </div>
        <div class="authentication-cont-r">
          <div class="set-account-cont-item">
            <div class="set-account-cont-item-l">关注公众号:</div>
            <div class="set-account-cont-item-r">
              <div></div>
              <div class="open">{{userDetail.weixin_subscribe == '1' ? '已绑定' : '未绑定'}}</div>
            </div>
          </div>
          <div class="authentication-status">
            <p :class="{'audit': status=='0','success':status=='1','fail':status=='2'}">{{ status ? statusObj[status] : '待认证' }}</p>
            <p>{{refuseReason}}</p>
          </div>
        </div>

      </div>
      <div class="authentication-cont">
        <div class="authentication-cont-tit">
          <span>*</span><span>技术方向(可选多个)</span>
        </div>
        <div class="authentication-cont-list">
          <p :class="item.status ? 'active' : ''" v-for="(item,index) in techDirection" :key="item.id" @click="changeItem(index,techDirection)">{{ item.name }}</p>
          <Input :disabled="true" v-if="techDirectionDes" v-model="techDirectionDes" placeholder="请输入内容" style="width: 300px;margin-bottom: 10px" />
        </div>
      </div>
      <div class="authentication-cont">
        <div class="authentication-cont-tit">
          <span>*</span><span>技术体系(可选多个)</span>
        </div>
        <div class="authentication-cont-list">
          <p :class="item.status ? 'active' : ''" v-for="(item,index) in techSystem" :key="item.id" @click="changeItem(index,techSystem)">{{ item.name }}</p>
          <Input :disabled="true" v-if="techSystemDes" v-model="techSystemDes" placeholder="请输入内容" style="width: 300px;margin-bottom: 10px" />
        </div>
      </div>
      <div class="authentication-cont">
        <div class="authentication-cont-tit">
          <span>*</span><span>含有经验(可选多个)</span>
        </div>
        <div class="authentication-cont-list">
          <p :class="item.status ? 'active' : ''" v-for="(item,index) in workExp" :key="item.id" @click="changeItem(index,workExp)">{{ item.name }}</p>
        </div>
      </div>
      <div class="authentication-cont">
        <div class="authentication-cont-tit">
          <span>*</span><span>个人简历</span>
        </div>
        <div class="authentication-cont-list">
          <p type="primary" class="active" >{{ fileName }}</p>
          <p class="preview" @click="checkResume(resumeUrl)">预览</p>
<!--          <Upload-->
<!--              type="drag"-->
<!--              :action="constant.URL + '/uploadfile/upload'"-->
<!--              :headers="{-->
<!--                  'access-token':Cookies.get('liteAccessToken')-->
<!--                }"-->
<!--              :data="{-->
<!--                  type:3-->
<!--                }"-->
<!--              accept=".pdf"-->
<!--              :format="['pdf']"-->
<!--              :on-success="fileSuccess"-->
<!--              :show-upload-list="false"-->
<!--              :on-format-error="formatError"-->
<!--              class="upload-cont"-->
<!--          >-->
<!--            <div class="upload-cont-info">-->

<!--              <div class="upload-cont-info-message">-->
<!--                <div class="resume">-->
<!--                  -->
<!--                </div>-->

<!--                <p>点击按钮选择试题文件，或拖拽试题文件到此区域</p>-->
<!--                <p>支持文件格式：.pdf</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </Upload>-->
        </div>
      </div>
      <div class="authentication-cont">
        <div class="authentication-cont-tit">
          <span></span><span>技术认证</span>
        </div>
        <div class="authentication-cont-list">
<!--          <div class="authentication-cont-list-add" @click="addCategory"><Icon type="md-add" /></div>-->
          <p class="active"  v-for="(item,index) in selectCategoryData">
            <span>{{ item.categoryName }}-{{item.secondCategoryName}}-{{ item.name }}</span>
          </p>

        </div>
      </div>
      <div class="authentication-cont">
        <div class="authentication-cont-tit">
          <span></span><span>技术认证相关证书</span>
        </div>
        <div class="authentication-cont-list">
          <div class="certificate-list">
            <div class="certificate-list-item" v-for="item in certificateList">
              <img :src="item.url">
              <div class="certificate-list-item-icon">
                <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
                <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
              </div>
            </div>
          </div>
<!--          <Upload-->
<!--              type="drag"-->
<!--              :action="constant.URL + '/uploadfile/upload'"-->
<!--              :headers="{-->
<!--                  'access-token':Cookies.get('liteAccessToken')-->
<!--                }"-->
<!--              :data="{-->
<!--                  type:3-->
<!--                }"-->
<!--              accept=".jpg,.jpeg,.png"-->
<!--              :format="['jpg','jpeg','png']"-->
<!--              :on-success="certificateFileSuccess"-->
<!--              :show-upload-list="false"-->
<!--              :on-format-error="formatError"-->
<!--              :on-exceeded-size="formatError"-->
<!--              :max-size="5120"-->
<!--              multiple-->
<!--          >-->
<!--            <div>-->
<!--              <Icon type="ios-add" :size="70" />-->
<!--            </div>-->
<!--          </Upload>-->
        </div>
      </div>
      <div class="authentication-cont" v-if="dataId && !isInfo">
        <div class="authentication-cont-tit">
          <span></span><span>合作项目</span>
        </div>
        <div class="authentication-cont-list">
          <Input v-model="joinProject" :disabled="true" placeholder="请输入合作项目" style="width: 100%" />
        </div>
      </div>
      <div class="authentication-cont" v-if="!isInfo">
        <div class="authentication-cont-tit">
          <span></span><span>备注</span>
        </div>
        <div class="authentication-cont-list">
          <Input v-model="remark" :disabled="true" type="textarea" :rows="4" placeholder="请输入备注" />
        </div>
      </div>
<!--      <Button type="primary" style="margin-right: 10px" @click="showSaveModal">提交</Button>-->
      <Button @click="cancel">返回</Button>
    </div>


  </div>
</template>

<script>
import Cookies from "js-cookie";
import util from '@/utils/tools.js';
import EditUser from '@/components/editUser.vue';
export default {
  components:{EditUser},
  name: "authentication.vue",
  data(){
    return {
      Cookies:Cookies,
      userId: '',
      userLoading: false,
      userList: [],
      list: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New hampshire', 'New jersey', 'New mexico', 'New york', 'North carolina', 'North dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode island', 'South carolina', 'South dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West virginia', 'Wisconsin', 'Wyoming'],
      // alipayAccount:'',
      email:'',
      uploadfileId:'',
      fileName:'',
      techDirection:[],
      techSystem:[],
      workExp:[],
      techDirectionDes:'',
      techSystemDes:'',
      certificationModal:false,
      categoryList:[],  //一级分类
      curCategoryIndex:0, //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryIndex:0, //当前二级分类
      thirdCategoryList:[],  //三级级分类
      selectCategoryData:[],
      certificateList:[],
      visible:false,
      certificateUrl:'',
      resumeUrl:'', //个人简历地址
      userName:'',
      dataId:'',
      remark:'',
      joinProject:'',
      timer:null,
      status:'',
      statusObj:{},
      refuseReason:'',
      saveModal:false,
      editUserModal:false,
      followModal:false,
      teacherWxCode:'',
      timer1:null,
      scene:'',
      userDetail:{},
    }
  },
  props:{
    isInfo:{
      type:Boolean,
      default:false,
    }
  },
  created(){

    this.userName = this.$route.query.name || '';
    if(this.userName){
      this.getUserList(this.$route.query.id,()=>{
        this.userId = this.$route.query.id || '';
      });
      this.getDetail(this.$route.query.id);
    }else{
      if(!this.isInfo){
        this.getTeacherForm();
      }
    }
    this.getUserDetail();
    this.getImage();
  },
  watch:{
    '$store.state.user.userInfo.id':{

      handler:function(newV,oldV){

        if(newV &&this.isInfo){
          this.userName = this.$store.state.user.userInfo.nickname;
          this.userId = this.$store.state.user.userInfo.id;
          this.getDetail(this.userId);
        }
      },
      immediate:true,
    }
  },
  methods:{
    editUserSuccess(data){
      this.editUserModal = false;
      this.getUserList(data,()=>{
        this.userId = '';
        this.$nextTick(()=>{
          this.userId = this.$route.query.id || '';
        })

      });
    },
    closeEditUser(){
      this.editUserModal = false;
    },
    showSaveModal(){
      if(this.isInfo){
        this.saveModal=true;
      }else{
        this.save();
      }
    },
    closeSave(){
      this.saveModal=false;
    },
    confirmSave(){
      this.save();
    },
    getDetail(userId){
      let params = {
        user_id:userId
      };
      this.api.user.userTeacherDetail(params).then((res)=>{
        if(res.info){
          this.dataId = res.info.id;
          this.statusObj = res.statuses;
          this.status = res.info.status;
          // this.alipayAccount = res.info.ali_account;
          this.email = res.info.email;
          res.info.auth_category_names.forEach((item)=>{
            let data = [];
            let obj = {};
            for(let name in item){
              data.push({
                id:name,
                name:item[name]
              })
            }
            obj = {
              categoryId:data[0].id,
              categoryName:data[0].name,
              secondCategoryId:data[1].id,
              secondCategoryName:data[1].name,
              id:data[2].id,
              name:data[2].name,
            }

            this.selectCategoryData.push(obj)
          })
          this.resumeUrl = res.info.resume;
          let urlArr = res.info.resume.split('/');
          this.fileName = urlArr[urlArr.length -1];

          this.certificateList = res.info.auth_cert ? res.info.auth_cert.split(',').map((item)=>{
            return {url:item};
          }) : [];

          this.joinProject = res.info.join_project;
          this.remark = res.info.remarks;
          this.refuseReason = res.info.refuse_reason;
        }

        this.getTeacherForm(res.info);
      })
    },
    changeItem(index,data){
      data[index].status = !data[index].status;
    },
    fileSuccess(response,file, fileList){  //上传文件成功
      // console.log(response.data.info,'dddd')
      this.uploadfileId = response.data.info.id;
      this.fileName = response.data.info.file_name;
      this.resumeUrl = response.data.info.upload_path;
      this.$Message.success('上传文件成功');
    },
    certificateFileSuccess(response){//上传证书成功
      this.certificateList.push({
        id:response.data.info.id,
        name:response.data.info.file_name,
        url:response.data.info.upload_path
      })
    },
    formatError(){
      this.$Message.warning('请上传正确格式文件');
    },
    handleView(data){
      this.visible = true;
      this.certificateUrl = data;
    },
    handleRemove(data){
      let index = this.certificateList.findIndex((item)=>{
        return item.id == data.id;
      });
      if(index != -1){
        this.$delete(this.certificateList,index);
      }
    },
    getTeacherForm(data){
      this.api.user.userTeacherForm().then((res)=>{
        this.techDirection = [];
        for(let name in res.tech_direction){
          let status = false;
          if(data && data.tech_direction_arr){
            for(let sName in data.tech_direction_arr){
              if(sName == 99){
                this.techDirectionDes = data.tech_direction_arr[sName];
              }
              if(sName == name){
                status = true;
              }
            }
          }
          if(status){
            this.techDirection.push({
              id:name,
              name:res.tech_direction[name],
              status:status
            })
          }

        }
        this.techSystem = [];
        for(let name in res.tech_system){
          let status = false;
          if(data && data.tech_system_arr){
            for(let sName in data.tech_system_arr){
              if(sName == 99){
                this.techSystemDes = data.tech_system_arr[sName];
              }
              if(sName == name){
                status = true;
              }
            }
          }
          if(status){
            this.techSystem.push({
              id:name,
              name:res.tech_system[name],
              status:status
            })
          }

        }
        this.workExp = [];
        for(let name in res.work_exp){
          let status = false;
          if(data && data.work_exp_arr){
            for(let sName in data.work_exp_arr){
              if(sName == name){
                status = true;
              }
            }
          }
          if(status){
            this.workExp.push({
              id:name,
              name:res.work_exp[name],
              status:status
            })
          }

        }
      })
    },

    addCategory(){
      this.certificationModal = true;
      this.getCategoryList();
    },
    getCategoryList(){
      let data = {
        keyword:this.keyword,
      };
      this.loading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.loading = false;
        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;
        this.getSecondList();

      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeFirstCategory(index){
      this.curCategoryIndex = index;
      this.getSecondList();
    },
    getSecondList(){
      let data = {
        keyword:this.keyword,
        category_id:this.categoryList[this.curCategoryIndex].id,
        // second_category_id:this.cursecondCategoryId
      };
      this.api.dataset.categoryForm(data).then((res)=>{
        //一级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];
        this.cursecondCategoryIndex = 0;
        this.getThreeList();
      })
    },
    changeSecondSearch(index){
      this.cursecondCategoryIndex = index;
      this.getThreeList();
    },
    getThreeList(){
      let data = {
        keyword:this.keyword,
        category_id:this.categoryList[this.curCategoryIndex].id,
        category_second_id:this.secondCategoryList[this.cursecondCategoryIndex].id
      };
      this.api.dataset.categoryForm(data).then((res)=>{
        //三级标签
        this.thirdCategoryList = [
          ...res.categoryThirdList
        ];
        this.thirdCategoryList.forEach((item)=>{
          let index = this.selectCategoryData.findIndex((sItem)=>{
            return item.id == sItem.id;
          });
          if(index != -1){
            this.$set(item,'status',true);
          }else{
            this.$set(item,'status',false);
          }

        })
      })
    },
    changeThirdCategory(data){
      data.status = !data.status;
      if(data.status){
        let obj = {
          categoryId:this.categoryList[this.curCategoryIndex].id,
          categoryName:this.categoryList[this.curCategoryIndex].name,
          secondCategoryId:this.secondCategoryList[this.cursecondCategoryIndex].id,
          secondCategoryName:this.secondCategoryList[this.cursecondCategoryIndex].name,
          ...data
        }
        this.selectCategoryData.push(obj);
      }else{
        let index = this.selectCategoryData.findIndex((item)=>{
          return item.id == data.id;
        });
        if(index != -1){
          this.$delete(this.selectCategoryData,index);
        }
      }
    },
    deleteTag(index){
      this.$delete(this.selectCategoryData,index);
    },
    getUserList(data,fn){
      let params = {
        pageSize:20,
        page:1,
        keyword:data || '',
      };

      this.userLoading = true;
      this.api.production.userList(params).then((res)=>{
        this.userLoading = false;
        this.userList = res.list;
        // console.log(this.userList,'this.userList')
        // if(type == 'edit'){
        //   this.userId = this.$route.query.id || '';
        // }
        fn&&fn();

      }).catch((err)=>{
        this.userLoading = false;
      })
    },
    remoteMethod (query) {
      if (query !== '') {
        if(this.timer) return;
        this.timer = setTimeout(()=>{

          this.getUserList(query);
          this.timer = null
        },500)
        // this.getUserList(query);
      } else {
        // console.log('111111')
        this.userList = [];
      }
    },
    cancel(){
      this.$router.go(-1)
    },
    save(){
      if(!this.userId){
        this.$Message.warning('请选择用户');
        return;
      }
      // if(!this.alipayAccount){
      //   this.$Message.warning('请输入支付宝账号');
      //   return;
      // }
      // if(!this.email){
      //   this.$Message.warning('请输入邮箱');
      //   return;
      // }
      if(this.email && !util.isEmail(this.email)){
        this.$Message.warning('请输入正确格式邮箱');
        return;
      }

      let techDirectionData = this.techDirection.filter((item)=>{
        return item.status;
      });
      if(!techDirectionData.length){
        this.$Message.warning('请选择技术方向');
        return;
      }
      let len = techDirectionData.filter((item)=>{
        return item.name == '其他'
      }).length;

      techDirectionData = techDirectionData.map((item)=>{
        return item.id
      });
      if(len){
        if(!this.techDirectionDes){
          this.$Message.warning('请输入内容');
          return;
        }
        techDirectionData.push(
            encodeURIComponent(this.techDirectionDes)
        );
      }

      let techSystemData = this.techSystem.filter((item)=>{
        return item.status;
      });
      if(!techSystemData.length){
        this.$Message.warning('请选择技术体系');
        return;
      }
      let techSystemLen = techSystemData.filter((item)=>{
        return item.name == '其他'
      }).length;
      techSystemData = techSystemData.map((item)=>{
        return item.id
      });
      if(techSystemLen){
        if(!this.techSystemDes){
          this.$Message.warning('请输入内容');
          return;
        }
        techSystemData.push(
            encodeURIComponent(this.techSystemDes)
        )
      }

      let workExpData = this.workExp.filter((item)=>{
        return item.status;
      }).map((item)=>{
        return item.id
      });
      if(!workExpData.length){
        this.$Message.warning('请选择行业经验');
        return;
      }
      if(!this.resumeUrl){
        this.$Message.warning('请上传个人简历');
        return;
      }

      let categoryIds = this.selectCategoryData.map((item)=>{
        return item.categoryId + '|' + item.secondCategoryId + '|' + item.id;
      })

      let authCert = this.certificateList.map((item)=>{
        return item.url;
      })

      let params = {
        user_id:this.userId,
        // ali_account:this.alipayAccount,
        email:this.email,
        tech_direction:techDirectionData.join(','),
        tech_system:techSystemData.join(','),
        work_exp:workExpData.join(','),
        resume:this.resumeUrl,
        auth_category_ids:categoryIds.join(','),
        auth_cert:authCert.join(','),
        remarks:this.remark,
        join_project:this.joinProject
      }
      if(!this.info){
        params.site_id=-1;
      }
      if(this.dataId){
        this.api.user.userTeacherUpdate(params).then((res)=>{
          this.$Message.success('编辑成功');
          this.$router.go(-1);
        })
      }else{
        this.api.user.userTeacherCreate(params).then((res)=>{
          this.$Message.success('操作成功');
          this.$router.go(-1);
        })
      }


    },
    editUser(){
      this.editUserModal = true;
    },
    checkResume(data){
      window.open(data,'_blank')
    },
    goInfo(data){
      this.current = data;
      if(data ==0 || data ==1){
        this.status = false;
      }
      if(data == 2){
        if(this.userDetail.weixin_subscribe == '1'){
          this.$Message.warning('已绑定');
          return;
        }
        this.followModal = true;
        clearInterval(this.timer1);
        this.timer1 = setInterval(()=>{
          this.getWxDetail();
        },1000)
      }

    },
    clearTimer(){
      console.log('2222')
      clearInterval(this.timer1);
    },
    getImage(){ //获取二维码图片
      this.api.index.WeixinServerQrShow().then((res)=>{
        this.teacherWxCode = res.showqrcode;
        this.scene = res.scene;
      })
    },
    getWxDetail(){
      let params = {
        scene:this.scene
      };
      this.api.index.WeixinServerQrBinding(params).then((res)=>{
        if(res.weixin_subscribe == '1'){
          clearInterval(this.timer1);
          this.followModal = false;
          this.$emit('editSuccess')
        }
      })
    },
    getUserDetail(){
      let params = {
        with_weixin_subscribe:1
      };
      if (this.userId){
        params.user_id = this.userId
      }

      this.api.user.userDetail(params).then((res)=>{
        this.userDetail = res.user;
      })
    },
  }
}
</script>

<style scoped lang="scss">
.noPadding{
  box-shadow: none !important;
  padding:0 !important;
  margin: 0 !important;
}
.authentication{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  font-size: 14px;

  .flex-layout{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .authentication-cont{
    margin-bottom: 20px;
    padding: 20px;
    border:1px solid #dcdee2;

    .authentication-cont-r{
      flex: 1;
      width: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .authentication-status{
      flex: 1;
      width: 0;
      margin-left: 30px;
      >p:nth-child(1){
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        margin-bottom: 5px;
      }
      .audit{
        color:#2d8cf0;
      }
      .success{
        color:#19be6b;
      }
      .fail{
        color:#ed4014;
      }
    }
    .authentication-cont-item{
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;

      >p{
        margin-right: 10px;
        width: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        >span:first-child{
          padding-top: 5px;
          margin-right: 5px;
          color:red;
          font-size: 20px;
        }
      }
    }
    .authentication-cont-item:last-child{
      margin-bottom: 0;
    }
    .authentication-cont-tit{
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span:first-child{
        padding-top: 5px;
        margin-right: 5px;
        color:red;
        font-size: 20px;
      }
    }
    .authentication-cont-list{
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      >p{
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 10px;
        height: 32px;
        line-height: 32px;
        border:1px solid #dcdee2;
        border-radius: 4px;
        cursor: pointer;
      }
      >p.active{
        color:#2d8cf0;
        border:1px solid #2d8cf0;
      }
      .upload-cont{
        margin: 0 auto;
        width: 100%;

        .upload-cont-info{
          margin: 0 auto;
          width: 340px;
          .upload-cont-info-message{
            margin: 20px 0;
            padding-left: 20px;
            text-align: left;
            line-height: 28px;
          }
        }
      }
      .authentication-cont-list-add{
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 5px;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .certificate-list{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .certificate-list-item{
          margin-right: 10px;
          width: 70px;
          height: 70px;
          text-align: center;
          line-height: 70px;
          position: relative;
          >img{
            width: 100%;
            height: 100%;
          }

          .certificate-list-item-icon{
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0,0,0,.6);

            >i{
              color: #fff;
              font-size: 20px;
              cursor: pointer;
              margin: 0 2px;
            }
          }
        }
        .certificate-list-item:hover .certificate-list-item-icon{
          display: block;
        }
      }
    }
  }
}
.authentication-cont-list-tag{
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  cursor: pointer;
  >span{
    margin-right: 10px;
  }
}
.search{
  margin-bottom: 20px;
  .search-item{
    margin-bottom: 15px;
    font-size: 14px;

    .name{
      margin-bottom: 5px;
      padding: 5px 0;
      width: 75px;
      text-align: right;
    }
    .cont{
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values{
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div{
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 5px 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
          border: 1px solid #dcdee2;
          border-radius: 4px;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
          border-radius: 4px;
        }
      }
      .more{
        padding: 5px 0;
        width: 80px;
        text-align: right;
        cursor: pointer;
        .more-icon{
          transition: transform 0.3s linear;
        }
      }
    }
  }
  .select-data{
    padding: 20px;
    border: 1px solid #dcdee2;
    border-radius: 4px;

    .select-data-tit{
      font-weight: bold;
      font-size: 14px;
    }
    .select-data-cont{
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

    }
  }

}
.save-message{
  font-size: 14px;
  text-align: center;
  line-height: 30px;
}
.resume{
  display: flex;
  justify-content: center;
  align-items: center;

  .preview{
    margin-bottom: 20px;
    padding: 10px;
    color:#2d8cf0;
    font-size: 14px;
  }
}
.set-account-cont-item{
  margin-left: 30px;
  width: 150px;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .set-account-cont-item-r{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >div:nth-child(2){
      margin-left: 5px;
      text-align: right;
      color:#c5c8ce;
      cursor: not-allowed;

    }
  }
  .open{
    color:#2d8cf0 !important;
    cursor: pointer !important;
  }
}

</style>
