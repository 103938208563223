<template>
  <el-drawer
      :visible.sync="editUserModal"
      title="编辑用户"
      @close="cancel"
  >
    <div class="drawer-title" slot="title">
      <div class=drawer-title-text>编辑用户</div>
      <div class="drawer-title-btns">
        <Button type="primary" @click="confirm" :loading="editLoading">确定</Button>
      </div>
    </div>
    <div style="padding: 20px;margin-top: 20px;">
      <el-form ref="formValidate" :model="formItem" :rules="formRule" label-width="80px">
        <el-form-item label="所属租户" prop="site_id">
          <!--        <Select filterable v-model="formItem.site_id">-->
          <!--          <Option :value="item.id" v-for="item in siteList" :key="item.id + item.name">ID:{{ item.id }}  {{ item.name }}</Option>-->
          <!--        </Select>-->
          <el-select v-model="formItem.site_id" ref="select" remote clearable :remote-method="rankRemoteSite" filterable
                     placeholder="请选择组织" >
            <el-option v-for="item in siteList" :value="item.id" :key="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formItem.name" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="formItem.nickName" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input v-model="formItem.mobile" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="username">
          <el-input v-model="formItem.username" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="formItem.password" placeholder="Enter something..."></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-select multiple v-model="formItem.role">
            <el-option :value="item.value" v-for="item in roleList" :key="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="formItem.status">
            <el-option :value="item.value" v-for="item in statusList" :key="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer">
      <Button @click="cancel">取消</Button>
      <Button type="primary" @click="confirm" :loading="editLoading">确定</Button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "editUser.vue",
  data(){
    const validateMobile = (rule, value, callback) => {
      if (value.length <= 0) {
        return callback();
      }

      if (value.length > 11) {
        return callback(new Error(this.$t('手机号码最长11位')));
      }
      if (value.length < 11) {
        return callback(new Error(this.$t('手机号码最少11位')));
      }
      callback();
    };
    return{
      userModal:true,
      formItem:{
        name:'',
        nickName:'',
        mobile:'',
        role:'',
        status:'0',
        username:'',
        password:'',
        site_id:'',
      },
      formRule: {
        site_id: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        name: [
          { required: false, message: '请输入名称', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
        username: [
          { required: false, trigger: 'blur'  },
        ],
        mobile: [
          { required: false, validator: validateMobile, trigger: 'blur'  },
        ],
        password: [
          { required: false, trigger: 'blur'  },
        ],
        role: [
          { required: true, message: '请选择角色' },
        ],
        status: [
          { required: true, message: '请选择状态' },
        ]
      },
      siteList:{},
      roleList:[],
      statusList:[],
      editLoading:false,
    }
  },
  props:{
    userId:{
      type:String,
      default:''
    },
    editUserModal:{
      type:Boolean,
      default: false,
    }
  },
  created(){
    this.getInfo();
    this.getFromData();
  },
  methods:{
    getInfo(){ //编辑
      // console.log(this.userId,'this.userId')
      let params = {
        user_id:this.userId
      }
      this.api.user.userDetail(params).then((res)=>{
        this.formItem.site_id = res.user.site.id;
       this.$nextTick(()=>{
         this.getSiteList(res.user.site.name);
       })

        this.formItem.name = res.user.realname;
        this.formItem.nickName = res.user.nickname;
        this.formItem.username = res.user.username;
        this.formItem.mobile = res.user.mobile;
        this.formItem.password = res.user.password;
        this.formItem.role = [];
        if(res.user.auth_roles){
          for(let name in res.user.auth_roles){
            this.formItem.role.push(name)
          }
        }
        this.formItem.status = res.user.status;
      })





      this.userModal = true;
    },
    getFromData(){  //获取用户的角色列表
      let params = {site_id:-1};
      this.api.user.userForm(params).then((res)=>{
        // this.siteList = res.sites;
        this.roleList = [];
        for(let name in res.roles){
          this.roleList.push({
            label:res.roles[name],
            value:name
          })
        }

        this.statusList = [];

        for(let name in res.statuses){
          this.statusList.push({
            label:res.statuses[name],
            value:name
          })
        }
      })
    },
    getSiteList(data) {
      let params = {
        pageSize: 20,
        page: 1,
        keyword: data,
      };
      this.api.site.siteList(params).then((res) => {
        this.siteList = res.list;
      })
    },
    rankRemoteSite(query) {
      console.log(query, 'query')
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getSiteList(query);
      }, 300)

    },
    cancel(){
      this.userModal = false;
      this.$refs.formValidate.resetFields();
      this.$emit('close')
    },
    confirm(){
      this.$refs['formValidate'].validate((valid) => {
        if (valid) {
          let params = {
            site_id:this.formItem.site_id,
            realname:this.formItem.name,
            nickname:this.formItem.nickName,
            username:this.formItem.username,
            mobile:this.formItem.mobile,
            password:this.formItem.password,
            roles:this.formItem.role.join(','),
            status:this.formItem.status,
            user_id:this.userId
          };

          this.editLoading = true;

          this.api.user.userUpdate(params).then((res)=>{
            this.editLoading = false;
            this.userModal = false;
            // console.log(this.formItem.nickName,'this.formItem.nickName')
            this.$emit('success',this.formItem.nickName)
          }).catch((err)=>{
            this.editLoading = false;
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
